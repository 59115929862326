import { App, defineAsyncComponent } from 'vue'

import AuthProvider from './AuthProvider.vue'

const Success = defineAsyncComponent(() => import('./Success.vue'))
const MasterSlaveController = defineAsyncComponent(() => import('./MasterSlaveController.vue'))
const ChangedRulesModal = defineAsyncComponent(() => import('./ChangedRulesModal.vue'))
// const GoToTop = defineAsyncComponent(() => import('./GoToTop.vue'))

const register = (app: App<Element>): void => {
  app
    .component('AuthProvider', AuthProvider)
    .component('Success', Success)
    .component('MasterSlaveController', MasterSlaveController)
    .component('ChangedRulesModal', ChangedRulesModal)
    // .component('GoToTop', GoToTop)
}

export default {
  register,
}
